import React, { useState, useEffect } from 'react'

import TaskCard from './TaskCard'

import { Droppable } from 'react-beautiful-dnd'

import EditRouteScheduleForm from './EditRouteScheduleForm'
import ManuallyScheduleRouteForm from './ManuallyScheduleRouteForm'

export default function RouteContainer({ displayDays, updateRouteTemplate, column, tasks, drivers, locations, count, weekday, courierPartners, setRouteCreationMsg}) {

  const [lock, setLock] = useState(false);
  const [closeScheduleRouteModal, setCloseScheduleRouteModal] = useState(true)
  const [collapsed, setCollapsed] = useState(false)
  const isDropDisabled = lock;
  const isDragDisabled = lock;

  useEffect(() => {
    if (column.locked_at != null && lock === false) {
      setLock(true)
    }
  }, [])
  async function lockAPI(template_id, timeLocked) {
    const locked_at = { locked_at: timeLocked }
    const id = Number(template_id)
    const response = await fetch(`/api/route_templates/${id}`, {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(locked_at),
      credentials: "same-origin"
    });

    const result = await response.json();

    return result;
  }

  function toggleLock(e) {
    e.preventDefault();
    setLock(!lock)
    
    const timestamp = (new Date).toISOString().slice(0, 19)
    const lockTime = (new Date).toTimeString().slice(12, 17)
    const lockDateTime = timestamp.concat(lockTime)
    const timeLocked = lock ? '' : lockDateTime

    lockAPI(e.target.value, timeLocked)
  }

  const toggleCollapse = (e) => {
    e.preventDefault()
    setCollapsed(!collapsed)
  }

  const toggleScheduleRouteModal = (e) => {
    e.preventDefault()
    setCloseScheduleRouteModal(!closeScheduleRouteModal)
  }
  return (
    <div className='row bg-white borderRadius-s boxShadow p1 m1 w-240px h-auto' style={{backgroundColor: "#f9fafc", alignSelf: "start"}}>
      <div style={{ display: 'flex', height: "50px",padding: "0 0 0 0", marginBottom: "1%" }} className="row p1">
        <p style={{fontWeight: "bolder", fontSize: "1.4rem", padding: "0 0 0 0"}}> Route {column.id}</p>
        <p  style={{fontSize: "1.1rem", padding: "1%" }}> ({count} {count == 1 ? "Task" : "Tasks"})</p>
        {!lock &&  <button value={column.id} data-toggle="modal" data-target={`#edit-route-modal-${column.id}`} data-backdrop="static" data-keyboard="false" className="routeEditButton" style={{ color: "#0047AB", border: "none", backgroundColor: "#f9fafc" }} > edit </button> }
        <EditRouteScheduleForm column={column} tasks={tasks} drivers={drivers} locations={locations} updateRouteTemplate={updateRouteTemplate} courierPartners={courierPartners}/>
        <button key={column.id} onClick={toggleLock} value={column.id} style={{ color: lock ? "#d9534f" : "#C8C8C8" , backgroundColor: "#f9fafc", height: "18px", padding: "0 3% 0 6%", border: "none" }} className="glyphicon glyphicon-lock"></button>
        <button data-toggle="collapse" data-target={".route-collapse-" + column.id} onClick={toggleCollapse} style={{ backgroundColor: "#f9fafc", height: "18px", padding: "0", border: "none"}} className={!collapsed ? "glyphicon glyphicon-menu-up" : "glyphicon glyphicon-menu-down"}></button>
      </div>
      <p style={{ lineHeight: "10px" }}><span style={{ fontWeight: "bold" }}>Pickup at: </span> { column.complete_after ? new Date(2000, 1, 1, column.complete_after.hour, column.complete_after.minute).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null } </p>
      <p style={{ lineHeight: "10px" }}><span style={{ fontWeight: "bold" }}>Courier Partner: </span>{column.courier_partner}</p>
      <p style={{ lineHeight: "10px" }}><span style={{ fontWeight: "bold" }}>Driver: </span>{drivers.map((item) => item.id == column.driver_id ? item.name : null)}</p>

      <div style={{ padding: "0 0 10% 0", backgroundColor: "#EFEFEF", borderRadius: "5px", border: "1px solid lightblue" }} className={"collapse in route-collapse-" + column.id}>
        <Droppable droppableId={column.id.toString()} isDropDisabled={isDropDisabled}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tasks.map((task, index) => (
                <TaskCard key={task.outpost_setting_id} task={task} index={index} isDragDisabled={isDragDisabled} displayDays={displayDays} updateRouteTemplate={updateRouteTemplate} column={column}> {index} </TaskCard>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div style = {{display: 'flex', justifyContent: 'center', margin: '2% 0 0 0 '}}>
        <button className="btn btn-md btn-primary" value={column.id} onClick={toggleScheduleRouteModal} data-toggle="modal" data-target={`#edit-schedule-route-modal-${column.id}`} data-backdrop="static" data-keyboard="false">Create Route</button>
        <ManuallyScheduleRouteForm closeScheduleRouteModal={closeScheduleRouteModal} setCloseScheduleRouteModal={setCloseScheduleRouteModal} column={column} updateRouteTemplate={updateRouteTemplate} weekday={weekday} setRouteCreationMsg={setRouteCreationMsg}/>
      </div>
    </div>
  )
}
